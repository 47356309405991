.error {
  background-color: #eb4034;
}

.info {
  background-color: #3f51b5ff;
}

.warn {
  background-color: rgb(245, 124, 0);
}

.alert {
  line-height: 23px;
  color: white;
  padding: 20px 10px;
  border-radius: 7px;
  align-items: center;
  display: flex;
}

.alert:not(:first-child) {
  margin-top: 10px;
}

.alert > .MuiSvgIcon-root {
  margin-right: 7px;
}
